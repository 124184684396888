header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 2rem;
  .aside {
    flex: 1;
  }
  .title {
    flex-basis: 100%;
    order: 3;
    margin-bottom: 0;
  }
  .icons {
    display: inline-flex;
    justify-content: flex-end;
    > * {
      margin-left: 0.5rem;
    }
  }
}

.page {
  display: flex;
  max-width: 1024px;
  min-height: 100%;
  flex-direction: column;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    .aside {
      display: inline-flex;
      flex: 1;
      > * + * {
        margin-left: 0.5rem;
      }
      &.right {
        justify-content: flex-end;
      }
    }
    .project-name {
      flex: 1 0 100%;
      order: 3;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
    color: $bright;
    font-style: italic;
    font-weight: 500;
  }
}

.project-name {
  .heading {
    max-width: 25rem;
    margin: 0 auto;
  }
}

footer {
  color: $primary;
  text-align: center;
}

@media screen and (min-width: 768px) {
  header {
    .title {
      flex-basis: auto;
      order: initial;
      margin-top: 0;
    }
  }
  .page {
    .start {
      margin-top: 3.5rem;
    }
    .top .project-name {
      flex: 2;
      order: initial;
      .heading {
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }
  }
}
