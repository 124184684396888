.home {
  align-items: center;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.25), transparent),
    url(../img/bg-main.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .top {
    width: 100%;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 1rem;
    .icon-box {
      position: relative;
      display: inline-block;
      overflow: visible;
      &.animation {
        width: 2rem;
        span {
          position: absolute;
          top: -1.25rem;
          display: block;
          height: 1.75rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 75%;
        }
      }
      &.reference {
        width: 1.5rem;
        svg {
          position: absolute;
          top: -0.9rem;
          width: 100%;
          height: auto;
          fill: $primary;
        }
      }
    }
    b {
      color: $primary;
    }
  }

  .animations {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    .animation {
      width: 6.5rem;
    }
  }

  .bottom {
    width: 100%;
    max-width: 45rem;
    a {
      flex: 1;
      text-align: center;
      + a {
        margin-top: 0.5rem;
      }
    }
    .eger,
    .impressum {
      flex-basis: 100%;
    }
    .nka,
    .skc,
    .ww {
      text-align: center;
      img {
        height: 2.5rem;
      }
    }
    .impressum {
      line-height: 2em;
    }
  }

  .start {
    margin-top: 2rem;
    text-align: center;
    span {
      display: inline-block;
      padding: 1rem;
      color: $primary;
      font-weight: 700;
      text-decoration: none;
    }
  }
}

.mode-accessibility {
  .home {
    background-image: none;
    .start span {
      background-image: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .home .bottom {
    width: 45rem;
    a + a {
      margin-top: 0;
    }
    .eger,
    .impressum {
      flex-basis: 0;
    }

    .eger {
      text-align: left;
    }

    .impressum {
      text-align: right;
    }

    .nka,
    .skc{
      height: 2rem;
      margin-top: -0.5rem;
    }
  }
}
