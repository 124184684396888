.logo {
  height: 2rem;
  &.main {
    width: 14rem;
    &.primary {
      fill: $primary;
    }
    &.light {
      fill: $bright;
    }
  }
  &.wordwall {
    width: 7rem;
    &.light #Group-21 path {
      fill: #ffffff;
    }
  }
}
