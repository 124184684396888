html {
  height: 100%;
  font-size: $font-size;
  &.mode-accessibility {
    background-color: #000000;
  }
}

body {
  height: 100%;
  color: $font-color-primary;
  font-family: $font-family;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  scrollbar-color: $primary $scrollbar-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px $font-color-secondary;
    box-shadow: inset 0 0 2px $font-color-secondary;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
  }
}

#root {
  height: 100%;
}

*:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

a,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
