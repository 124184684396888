$primary: var(--primary);
$secondary: var(--secondary);

$bright: var(--bright);

$font-family: var(--font-family);
$font-size: var(--font-size);

$font-color-secondary: var(--font-color-secondary);
$font-color-primary: var(--font-color-primary);

$bg-textbox: var(--bg-textbox);

$scrollbar-color: var(--scrollbar-color);
