.topics {
  max-width: 45rem;
  height: 100%;
  padding: 1rem;
  margin: 0 auto;
  background-color: $bg-textbox;
  border-radius: 0.75rem;
  .main-content-loader {
    margin-top: 25%;
    margin-bottom: 25%;
    text-align: center;
  }
  p {
    margin-bottom: 2rem;
    color: $font-color-secondary;
  }
}

.nav-topic {
  display: block;
  max-width: 27rem;
  margin: 0 auto;
  color: $primary;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: $secondary;
  }
  &:after {
    display: block;
    width: 85%;
    border-bottom: 2px solid $secondary;
    margin: 0.25rem auto 1rem;
    content: ' ';
  }
}

@media screen and (min-width: 768px) {
  .topics {
    width: 45rem;
    padding: 1rem 2rem 1.5rem;
  }
}
