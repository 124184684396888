.record-gallery {
  margin-top: 2rem;
  margin-right: -0.5rem;
  margin-bottom: 2rem;
  margin-left: -0.5rem;
  .animation {
    width: 100%;
    height: 100%;
  }
  .record-thumbnail {
    padding: 0.5rem;
    .avatar {
      padding-top: 100%;
      border: 1px solid $primary;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
    }
  }
  .chevron-left,
  .chevron-right {
    display: none;
    width: 2.5rem;
    height: 3rem;
    cursor: pointer;
    fill: $font-color-secondary;
    user-select: none;
  }
}

.record {
  flex: 1;
  .content-details {
    .text p {
      margin-bottom: 0;
    }
  }
}

.fun-fact {
  .toggler {
    color: $primary;
    font-weight: 700;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
  .text {
    padding: 0.5rem;
    border: 2px solid $primary;
    margin-top: 0.5rem;
    border-radius: 3px;
    color: $font-color-secondary;
    font-style: italic;
    span {
      margin-right: 1rem;
      font-weight: 700;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .record-gallery {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    .carousel-container {
      flex: 1;
    }
    .chevron-left,
    .chevron-right {
      display: block;
    }
  }
  .fun-fact {
    padding: 0 6rem;
  }
}

@media screen and (min-width: 1024px) {
  .fun-fact {
    padding: 0 8rem;
  }
}
