.content-details {
  display: grid;
  grid-template: repeat(4, auto) / auto 1fr auto;
  grid-template-areas:
    'prev refs next'
    'media media media'
    'caption caption caption'
    'text text text';
  row-gap: 0.5rem;
  .references {
    height: 1.75rem;
    align-self: center;
    margin-bottom: 1rem;
    grid-area: refs;
  }
  .previous-element {
    grid-area: prev;
  }
  .next-element {
    grid-area: next;
    text-align: right;
  }
  .media {
    position: relative;
    grid-area: media;
  }
  .caption {
    font-size: 0.75rem;
    font-style: italic;
    grid-area: caption;
    text-align: center;
  }
  .text {
    grid-area: text;
    .text-loader {
      margin-top: 50%;
      text-align: center;
    }
    h3:first-child {
      margin-top: 0;
    }
    p {
      margin-bottom: 0;
      text-align: left;
    }
    div {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-style: italic;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 0.5rem;
        p {
          padding-left: 2rem;
        }
        p + p {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .element-nav {
    align-self: center;
    user-select: none;
    svg {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      fill: $font-color-secondary;
      user-select: none;
    }
  }
}

.image-viewer {
  position: relative;
  display: flex;
  height: 27rem;
  align-items: center;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.2s linear;
    &.ready {
      opacity: 1;
    }
  }
  .image-loader {
    position: absolute;
    top: calc(13.5rem - 25px);
    left: calc(50% - 25px);
  }

  .fullscreen {
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    fill: $bright;
  }
}

.ril__navButtons {
  width: 3rem;
  height: 3rem;
  padding: 0;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ril__navButtonPrev {
  background-image: url('../svg/chevron-left.svg');
}

.ril__navButtonNext {
  background-image: url('../svg/chevron-right.svg');
}

.ril__toolbar {
  left: auto;
  background: transparent;
}

.ril__closeButton {
  background-image: url('../svg/times.svg');
}

.animation {
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 75%;
  svg {
    width: 100%;
    height: 100%;
    stroke: $primary;
  }
}

@media screen and (min-width: 768px) {
  .content-details {
    flex: 1;
    column-gap: 1rem;
    grid-template-areas:
      'refs refs'
      'media text'
      'caption .';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    &.pageable {
      grid-template-areas:
        'refs refs refs refs'
        'prev media text next'
        'prev caption . next';
      grid-template-columns: auto 1fr 1fr auto;
    }
    .element-nav {
      width: 5rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
    .text {
      position: relative;
      overflow-y: auto;
      > p {
        position: absolute;
        padding-right: 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .content-details {
    &.pageable {
      .element-nav {
        width: 7rem;
      }
    }
    &:not(.pageable) {
      grid-template-columns: 2fr 1fr;
    }
  }
}
