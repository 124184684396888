.impressum {
  .content {
    text-align: center;
    .intro {
      margin-top: 2rem;
      margin-bottom: 5rem;
    }
    .group {
      margin-bottom: 5rem;
      span {
        display: block;
        margin-bottom: 1rem;
      }
      .group-title {
        font-weight: 700;
      }
    }
  }

  .rights {
    font-weight: 700;
  }
  .bottom {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
    column-gap: 2rem;
    .logo {
      height: 6rem;
      &.wordwall {
        width: 8rem;
      }
    }
    .skc .logo {
      height: 4rem;
      margin-top: 0.75rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .impressum {
    .bottom {
      column-gap: 5rem;
    }
  }
}
