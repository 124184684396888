p {
  margin-top: 0;
  line-height: 1.5em;
  text-align: justify;
}

.project-title {
  h1,
  h2 {
    font-weight: 400;
  }

  h1 {
    display: flex;
    justify-content: center;
    color: $primary;
    font-size: 1.25rem;
    text-transform: uppercase;
  }

  .subtitle {
    padding: 0.25em;
    color: $secondary;
    text-align: center;
    &:first-of-type {
      margin-bottom: 0.5rem;
      font-style: italic;
    }
    &:last-of-type {
      font-size: 0.6rem;
      letter-spacing: 0.3em;
      text-transform: uppercase;
    }
  }

  .segment {
    flex: 1;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    border-bottom: 2px solid $secondary;
    &:first-child {
      text-align: right;
      span {
        padding-right: 0.75em;
      }
    }
    &:last-child {
      text-transform: none;
    }
  }
  .barrel {
    color: #f9f3cd;
    font-size: 1.45em;
    line-height: 1em;
    .barrel-display {
      top: -40%;
      right: 0;
      left: 0;
      height: 170%;
    }
  }
}

h1.title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5em;
  text-align: center;
  text-transform: uppercase;
}

h2.title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .project-title {
    &.lg {
      h1 {
        font-size: 1.5rem;
      }
      .subtitle {
        font-size: 0.75rem;
      }
    }
  }
}

.mode-accessibility {
  .project-title .barrel {
    color: $primary;
  }
}
