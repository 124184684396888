.action {
  color: $primary;
  cursor: pointer;
}

.language-selector {
  position: relative;
  font-size: 0.75rem;
  text-transform: uppercase;
  &.open {
    .dropdown {
      display: block;
    }
  }
  .dropdown {
    position: absolute;
    top: 2rem;
    display: none;
  }
}

.references {
  color: $primary;
  line-height: 0;
  text-align: center;
  a {
    margin: 0 0.25rem;
  }
}
