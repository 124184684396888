.barrel {
  position: relative;
  z-index: 1;
  padding: 2px 5px;
  .barrel-display {
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.mode-accessibility {
  .barrel {
    .barrel-display {
      path {
        fill: none;
      }
      stroke: $primary;
      stroke-width: 0.75rem;
    }
  }
}
